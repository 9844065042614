import { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Home from './homepage';
import Admin from './components/Admin';
import Contact from './components/Contact';
import ProtectedRoute from './ProtectedRoute';
import NoMatch from './components/NoMatch';
import axios from 'axios';
import './App.css';



function App() {
  const [user, setUser] = useState({ user: null, admin: false });

  useEffect(() => {
    axios.get('https://www.coreydevelops.com/auth/user', {
      headers: { "Content-Type": "application/json" },
      withCredentials: true,
    }).then((res) => {
      setUser(user => {
        return {...user, ...res.data};
      });
    }).catch(err => {
      setUser({ user: null, admin: false });
    });
   
  }, []);

  return (
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content="I am a Web Developer / Designer based in Scranton, PA looking for work in Software Development, UI / UX design, or Web Development. " />
          <meta name="robots" content="index,follow" />
          <meta name="copyright"content="Corey Develops"></meta>
          <meta name="author" content="Corey Riviello, coreyscodes@gmail.com"></meta>
          <meta name="url" content="https://www.coreydevelops.com"></meta>
          <meta property="og:description" content="Corey Develops offers Web Software and UI / UX design to help manage your business and grow it! Based out of Scranton, PA and served worldwide." />
          <meta name="coverage" content="Worldwide"></meta>
          <meta name="Classification" content="Business"></meta>
          <meta property="og:title" content="Open Graph Meta Tags: Corey Develops Web Design" />
          <meta property="og:url" content="https://www.coreydevelops.com" />
          <meta property="og:image" content="https://www.coreydevelops.com/logosquare.png" />
          <meta property="og:type" content="website" />
          <meta name="keywords" content="website, ui/ux design, software development, scranton, pa, react" />
          <title>Corey Develops | Software Development | Scranton, PA</title>
          <link rel="canonical" href="https://www.coreydevelops.com" />

      </Helmet>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home user={user} />} />
          <Route path="/admin" exact element={<ProtectedRoute user={user} />} >
            <Route path="/admin" exact element={<Admin user={user} />}/>
          </Route>
          <Route path="/admin/:id" element={<ProtectedRoute user={user} />} >
            <Route path="/admin/:id" element={<Contact user={user} />}/>
          </Route>
          <Route path="*" element={<NoMatch user={user}></NoMatch>}></Route>
        </Routes>
      </Router>
    </>
  )
}
export default App