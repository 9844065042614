import { Container } from 'react-bootstrap';
import { Card, CardHeader, Button, Divider } from '@mui/material';
import Aos from 'aos';
import './Jobs.css';
import { useEffect } from 'react';

function Jobs() {

useEffect(() => {
    Aos.init({ duration: 1000 });
    }, []);

  return (
    <section id="jobs" className="py-5">
        <Container className="py-5">
            <div className="jobs-container d-flex justify-content-center">
                <div className="jobs-head d-flex flex-column me-5">
                    <div className="job-wrapper">
                        <Card sx={{boxShadow: 10}} className='jobs-card px-3 py-3'>
                            <div data-aos="fade-up" className="jobs-list--item d-flex align-items-center py-3">
                                    <div className="image-container me-4">
                                        <img src="/images/prescriptioncenter.png" alt="The Prescription Center Scranton Logo"></img>
                                    </div>
                                    <span className="h5 my-0 fw-bold">The Prescription Center</span>
                                    <Button className="ms-auto" size="large"><a href="https://prescriptioncenterscranton.com/">View</a></Button>
                            </div>
                            <Divider data-aos="fade-up" data-aos-delay="100" className="px-3"></Divider>
                            <div data-aos="fade-up" data-aos-delay="100" className="jobs-list--item d-flex align-items-center py-3">
                                    <div className="image-container me-4">
                                        <img src="/images/colarussos.png" alt="Colarussos 2.0 Logo"></img>
                                    </div>
                                    <span className="h5 my-0 fw-bold">Colarussos 2.0</span>
                                    <Button className="ms-auto" size="large"><a href="https://colarussostwo.com/">View</a></Button>
                            </div>
                            <Divider data-aos="fade-up" data-aos-delay="200" className="px-3"></Divider>
                            <div data-aos="fade-up" data-aos-delay="200" className="jobs-list--item d-flex align-items-center py-3">
                                    <div className="image-container me-4">
                                        <img src="/images/westscrantonautopros.png" alt="West Side Auto Pros Scranton Logo"></img>
                                    </div>
                                    <span className="h5 my-0 fw-bold">West Scranton Auto Pros</span>
                                    <Button className="ms-auto" size="large"><a href="https://westscrantonautopros.com/">View</a></Button>
                            </div>
                        </Card>
                    </div>
                </div>
                <div className="jobs-body d-flex flex-column ms-5 mt-5 px-5">
                    <h1 className="fw-bold pb-3">Completed Jobs</h1>
                    <p className="h3 pb-4">Here is some information about me. I'm ready to be employed!</p>
                </div>
            </div>
        </Container>
    </section>
  )
}
export default Jobs
