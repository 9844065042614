import { useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Instagram, FacebookOutlined, Twitter } from '@mui/icons-material';
import cn from 'classnames';
import "./Navbar.css"

function Navigation(props) {
  const [expand, setExpand] = useState(false);

  function handleExpand() {
    setExpand((expand) => !expand);
  }
  
  return (
    <>
      <Navbar expanded={expand} onToggle={handleExpand} fixed="top" expand="lg">
          <Container>
          <Navbar.Brand style={{ cursor: 'pointer' }} onClick={props.handleHomeClick} href=""><img src="/images/logo.png" alt="Corey Develops Logo" /></Navbar.Brand>
          <Navbar.Toggle className={cn('hamburger', 'hamburger--emphatic', {'is-active': expand})} aria-controls="basic-navbar-nav">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className='link-container me-auto'>
              <Nav.Link href="" onClick={props.handleHomeClick} >Home</Nav.Link>
              <Nav.Link href="#jobs">Jobs</Nav.Link>
              <Nav.Link href="#skills">Skills</Nav.Link>
              <Nav.Link href="#about">About</Nav.Link>
              <Nav.Link onClick={props.handleContact} href="">Contact</Nav.Link>
            </Nav>
            <div className="d-flex ms-auto">
              <Nav.Link href="https://www.instagram.com/coreydevelops/"><Instagram fontSize='large'></Instagram></Nav.Link>
              <Nav.Link href="https://www.facebook.com/coreydevelops/"><FacebookOutlined fontSize='large'></FacebookOutlined></Nav.Link>
              <Nav.Link href="https://twitter.com/coreydevelops/"><Twitter fontSize='large'></Twitter></Nav.Link>
            </div>
          </Navbar.Collapse>
          </Container>
      </Navbar>
      <div className="nav-spacer"></div>
    </>
  )
}
export default Navigation