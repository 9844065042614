import { useState, useEffect } from 'react';
import { Container, Col, Row, Modal } from 'react-bootstrap';
import { Card, CardContent, CardHeader, Collapse, Avatar, Button, Stepper, TextField } from '@mui/material';
import { SchoolSharp } from '@mui/icons-material';
import Aos from 'aos';
import './About.css';

function About(props) {
    const [expand, setExpand] = useState(false);
    
  
    function handleClick() {
        setExpand((expand) => !expand);
    };

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, [])

    return (
        <section id="about">
            <Modal size="lg" show={expand} onHide={handleClick} centered>
                <Modal.Header closeButton>
                    <Modal.Title>A Little About Me</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p className="h4 px-3 py-3">Hello my name is Corey and I grew up in a small town in Pennsylvania. Growing up I always found a way to entertain myself, for example I played basketball and baseball, but then switched to piano and Jiu Jitsu at the age of 10. Along that whole time I would always go back to a computer at the end of the day and play video games. I usually played a game called Garry's Mod, which allowed people to write custom code for the game. That's when I discovered my love for coding. I would see all the cool things people build and I wanted to be able to build it and understand it myself, so I started studying Lua programming and cyber security aspects. When I reached 10th grade I joined a technical school, alongside with highschool, to study Computer Information Technology. In the tech school I studied computer concepts for IT work, but in my free time during and after school I studied Python and Javascript. Alongside from my coding I graduated with friends who I will always visit and keep up with. My friends, family, and teachers i've had over the time supported me, even when I only had the ideologies of becoming an engineer. I can't wait to learn more and if you'd like to know more about me, just contact me!</p>
                </Modal.Body>

            </Modal>

            <Container className="py-5">
                <Row>
                    <Col>
                        <div className="d-flex title-container flex-column ms-5 mt-5">
                            <h1 className="fw-bold pb-3">To Learn is to Grow</h1>
                            <p className="h3 pb-4">Here is some information about me. I'm ready to be employed!</p>
                            <Button variant="outlined" onClick={props.handleContact} size="large" type='button' className="secondary-button">Contact Now</Button>
                        </div>
                    </Col>
                    <div className="w-100 d-md-block d-lg-none py-5"></div>
                    <Col>
                        <div className="about-container d-flex justify-content-center">
                            <Card className="px-4 py-3" sx={{ boxShadow: 9, maxWidth: 400, overflow: 'hidden' }}>
                                <CardContent>
                                    <div className="d-flex">
                                        <Avatar src="/images/profile.jpg" alt="Picture of Owner of Corey Develops" sx={{ width: 75, height: 75 }}></Avatar>
                                        <div className="ms-4 d-flex flex-column justify-content-center">
                                            <h4 className="fw-bold">About Me</h4>
                                            <h5>6/16/2022</h5>
                                        </div>
                                    </div>
                                </CardContent>
                                <CardContent>
                                    <h4 className='pb-3 fw-bold'>Education</h4>
                                    <div className="d-flex flex-column">
                                        <div data-aos="fade-left" className="d-flex pb-3">
                                            <SchoolSharp fontSize="large"></SchoolSharp>
                                            <h5 className="ps-3">Wilkes Barre Career & Technical Center - Computer Information Technology</h5>
                                        </div>
                                        <div data-aos="fade-left" data-aos-delay="50" className="d-flex pb-3">
                                            <SchoolSharp fontSize="large"></SchoolSharp>
                                            <h5 className="ps-3">Old Forge High School - High School Diploma</h5>
                                        </div>
                                    </div>
                                    <h4 className='py-3 fw-bold'>Certifications</h4>
                                    <div className="d-flex flex-column">
                                        <div data-aos="fade-left" data-aos-delay="100" className="d-flex pb-3 align-items-center">
                                            <div className="d-flex">
                                                <img alt="IC3 Digital Literacy Certification" className="certification" src="/images/digital-literacy.png" alt="IC3 Digital Literacy Certification" />
                                            </div>
                                            <h5 className="ps-3">IC3 Digital Literacy</h5>
                                        </div>
                                    </div>
                                    <div className="pt-3 button-container d-flex justify-content-center">
                                        <Button size='large' variant='outlined' onClick={handleClick} className="secondary-button" type="button">View More</Button>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    </Col>
                </Row>
                
                
            </Container>
        </section>
    )
}
export default About