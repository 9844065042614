import { useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Button } from '@mui/material';
import { Instagram, FacebookOutlined, Twitter } from '@mui/icons-material';
import { LinkContainer } from 'react-router-bootstrap';
import cn from 'classnames';
import Navigation from "./AdminNavbar"
import "./Navbar.css"
import "./NoMatch.css"


function NoMatch({user}) {
  const [expand, setExpand] = useState(false);

  function handleExpand() {
    setExpand((expand) => !expand);
  }

  return (
    <>
      <Navbar expanded={expand} onToggle={handleExpand} fixed="top" expand="lg">
          <Container>
          <LinkContainer to="/">
            <Navbar.Brand style={{ cursor: 'pointer' }} href=""><img src="/images/logo.png" alt="Corey Develops Logo" /></Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle className={cn('hamburger', 'hamburger--emphatic', {'is-active': expand})} aria-controls="basic-navbar-nav">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className='link-container me-auto'>
                <LinkContainer to="/">
                    <Nav.Link href="">Home</Nav.Link>
                </LinkContainer>
            </Nav>
            <div className="d-flex ms-auto">
              <Nav.Link href="https://www.instagram.com/coreydevelops/"><Instagram fontSize='large'></Instagram></Nav.Link>
              <Nav.Link href="https://www.facebook.com/coreydevelops/"><FacebookOutlined fontSize='large'></FacebookOutlined></Nav.Link>
              <Nav.Link href="https://twitter.com/coreydevelops/"><Twitter fontSize='large'></Twitter></Nav.Link>
            </div>
          </Navbar.Collapse>
          </Container>
      </Navbar>
      <div className="nav-spacer"></div>
      <section id="no-match">
        <Container className="py-5">
            <h1 className="display-1 fw-bold">404</h1>
            <h1 className="display-5 pb-5">You Seem Lost!</h1>
            <h1>Return Home Here</h1>
            <LinkContainer to="/">
                <Button className="mt-3" variant="outlined" size="large">Home</Button>
            </LinkContainer>
        </Container>
      </section>
    </>
  )
}
export default NoMatch