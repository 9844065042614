import { useEffect, useState, useRef, useCallback } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Divider } from '@mui/material';
import cn from 'classnames';
import Aos from 'aos';
import './Header.css';


function Header(props) {

  useEffect(() => {
    window.scrollTo(0, 0);
    Aos.init({ duration: 1000 });
  }, []);
  
  return (
    <section id="header" onTransitionEnd={props.handleTransitionEnd}>
        <Container className="py-5">
            <Row className="py-5 header-head">
                <Col className="mt-5 d-flex flex-column align-items-start">
                  <div className="wrapper">
                    <h1 data-aos="fade-right" className="display-3">Corey Develops</h1>
                    <div className="line"></div>
                    <h2 data-aos="fade-right" data-aos-delay="200" className="display-5">Web Developer</h2>
                    <button type="button" onClick={props.handleContact} data-aos="fade-right" data-aos-delay="300" className="primary-button mt-5">Contact Now</button>
                  </div>
                    
                </Col>
                <Col className='text-center image-container d-flex justify-content-end px-5'>
                    <img data-aos="fade-right" data-aos-delay="400" src="/images/logo.png" alt="Corey Develops Logo"></img>
                </Col>
            </Row>
            <div data-aos="fade-up" data-aos-delay="300" className="quote-container d-flex flex-column align-items-center pt-5 mb-5 header-body">
              <p className='h2 text-center'>Hello, my name is Corey and I have a passion for UI / UX design and Web Development</p>
            </div>
            
            <div className="scroll-anchor"></div>
        </Container>
    </section>
  );
};

export default Header;