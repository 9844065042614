import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { TextField, Button, Snackbar, Alert } from '@mui/material';
import Aos from 'aos';
import axios from 'axios';
import validator from 'validator';
import Reaptcha from "reaptcha";
import { EmailRounded } from '@mui/icons-material';

import Navigation from './components/Navbar';
import Footer from './components/Footer';
import Header from './components/Header';
import Jobs from './components/Jobs';
import Skills from './components/Skills';
import About from './components/About';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'aos/dist/aos.css';
import 'hamburgers/dist/hamburgers.css';

function Home({ user }) {
  const [contact, setContact] = useState(false);
  const initalValues = { firstName: '', lastName: '', email: '', question: '' };
  const [formErrors, setFormErrors] = useState(initalValues);
  const [formValues, setFormValues] = useState(initalValues);
  const [verified, setVerified] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [captchaReady, setCaptchaReady] = useState(false);
  const [captchaRendered, setCaptchaRendered] = useState(false);
  const [success, setSuccess] = useState({ status: false, message: '' });
  const [failed, setFailed] = useState({ status: false, message: '' });
  let recaptchaRef = null;

  function showContact(e) {
    setContact(true);
  };

  function handleHomeClick() {
    window.scrollTo(0, 0);
  };

  function checkAll() {
    if(Object.values(formValues).filter(value => value.length === 0).length > 0) return setSubmitEnabled(false);
    if(Object.values(formErrors).filter(value => value.length > 0).length > 0) return setSubmitEnabled(false); 
    if(!verified) return setSubmitEnabled(false);
    return setSubmitEnabled(true); 
  };

  function validateForm(name, value) {
    switch(name) {
      case 'firstName':
        if (!validator.isAlphanumeric(value) || !validator.isAscii(value)) return setFormErrors({...formErrors, [name]: 'Please only letters and numbers!'});
        if (validator.isEmpty(value)) return setFormErrors({...formErrors, [name]: 'Please fill in this required field!'});
        if (!validator.isLength(value, { min: 1, max: 50 })) return setFormErrors({...formErrors, [name]: 'Please enter a value here!'});
        return setFormErrors({ ...formErrors, [name] : '' });
      
      case 'lastName': 
        if (!validator.isAlphanumeric(value) || !validator.isAscii(value)) return setFormErrors({...formErrors, [name]: 'Please only letters and numbers!'});
        if (validator.isEmpty(value)) return setFormErrors({...formErrors, [name]: 'Please fill in this required field!'});
        if (!validator.isLength(value, { min: 1, max: 50 })) return setFormErrors({...formErrors, [name]: 'Please enter a value here!'});
        return setFormErrors({ ...formErrors, [name] : '' });
      
      case 'email': 
        if (!validator.isAscii(value)) return setFormErrors({...formErrors, [name]: 'Please use proper characters!'});
        if (validator.isEmpty(value)) return setFormErrors({...formErrors, [name]: 'Please fill in this required field!'});
        if (!validator.isLength(value, { min: 1, max: 320 })) return setFormErrors({...formErrors, [name]: 'Please enter a value here!'});
        if (!validator.isEmail(value)) return setFormErrors({...formErrors, [name]: 'Please enter a valid email!'});
        return setFormErrors({ ...formErrors, [name] : '' });
  
      case 'question': 
        if (!validator.isAscii(value)) return setFormErrors({...formErrors, [name]: 'Please use proper characters!'});
        if (validator.isEmpty(value)) return setFormErrors({...formErrors, [name]: 'Please fill in this required field!'});
        if (!validator.isLength(value, { min: 1, max: 365 })) return setFormErrors({...formErrors, [name]: 'Please enter a value here!'});
        return setFormErrors({ ...formErrors, [name] : '' });
    }
  }

  function hideContact(e) {
    setContact(false);
    setCaptchaReady(false);
    setCaptchaRendered(false);
  };

  function handleLoad() {
    setCaptchaReady(true);
  };

  function handleChange(e) {
    const {name, value} = e.target;
    setFormValues({ ...formValues, [name]: value });
    validateForm(name, value);
    checkAll();
  };

  function handleVerify() {
    recaptchaRef.getResponse().then((value) => {
      if (!value) return setVerified(false);
      return setVerified(true);
    });
  };

  function handleSubmit(e) {
    e.preventDefault();
    checkAll();
    if (submitEnabled) {
      recaptchaRef.getResponse().then(value => {
        if (!value) return;
        axios.post("https://www.coreydevelops.com/contact", {
          headers: {
            "Content-Type": "application/json"
          },
          params: {
            ...formValues, reCaptcha: value
          }
        }).then(res => {
          recaptchaRef.reset().then(next => setVerified(false));
          if (res.status === 200) {
            setSuccess({ status: true, message: res.data.message });
            setFormValues({ ...initalValues });
          };
        }).catch(err => {
          recaptchaRef.reset().then(next => setVerified(false));
          if (err.response.status === 400) {
            setFailed({ status: true, message: err.response.data.message });
          };
        });
      });
    }
  };

  function handleStatusClose() {
    setFailed({ status: false, message: '' });
    setSuccess({ status: false, message: '' });
  };

  function handleTransitionEnd(e) {
    Aos.refresh();
  };

  useEffect(() => {
    checkAll();
  }, [verified]);


  useEffect(() => {
    if (!contact || captchaRendered || !captchaReady) return;
    recaptchaRef.renderExplicitly().then(val => setCaptchaRendered(true));
  }, [ contact, captchaReady ]);


  return (
   <>
      <Navigation handleContact={showContact} handleHomeClick={handleHomeClick}></Navigation>
      <Header handleTransitionEnd={handleTransitionEnd} handleContact={showContact}></Header>
      <Jobs></Jobs>
      <Skills></Skills>
      <About handleContact={showContact}></About>
      <Footer handleContact={showContact} handleHomeClick={handleHomeClick} user={user}></Footer>
      <Modal show={contact} onHide={hideContact} centered>
          <Modal.Header closeButton>
              <Modal.Title>Contact Me</Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-4 px-4">
              <div className="d-flex pb-3">
                <EmailRounded fontSize='medium' />
                <div className="px-1"></div>
                <h5 className="fw-bold">coreyscodes@gmail.com</h5>
              </div>
              <form onSubmit={handleSubmit}>
                  <div className="d-flex pb-3 justify-content-center">
                      <TextField className="flex-grow-1" inputProps={{ maxLength: 50, minLength: 1 }} error={formErrors.firstName === '' ? false : true} color={formErrors.firstName === '' ? 'success' : ''} helperText={formErrors.firstName} name="firstName" onChange={handleChange} value={formValues.firstName} id="first-name--input" label="First Name" variant="outlined" required/>
                      <div className="spacer px-2"></div>
                      <TextField className="flex-grow-1" inputProps={{ maxLength: 50, minLength: 1 }} error={formErrors.lastName === '' ? false : true} color={formErrors.lastName === '' ? 'success' : ''} helperText={formErrors.lastName} name="lastName" onChange={handleChange} value={formValues.lastName} id="family-name--input" label="Last Name" variant="outlined" required/>
                  </div>
                  <TextField inputProps={{ maxLength: 320, minLength: 1 }} error={formErrors.email === '' ? false : true}  color={formErrors.email === '' ? 'success' : ''} helperText={formErrors.email} name="email" onChange={handleChange} value={formValues.email} className="pb-3 w-100" id="email-input" label="Email" type="email" variant="outlined" required/>
                  <TextField inputProps={{ maxLength: 365, minLength: 1 }} error={formErrors.question === '' ? false : true} color={formErrors.question === '' ? 'success' : ''} helperText={formErrors.question} name="question" onChange={handleChange} value={formValues.question} className="pb-3 w-100" id="question-input" label="Question" rows={3} multiline variant="outlined" required/>
                  <div className="d-flex justify-content-center pb-3">
                      <Reaptcha ref={e => (recaptchaRef = e)} onVerify={handleVerify} onExpire={handleVerify} onLoad={handleLoad} explicit sitekey='6LfvRI0gAAAAAKOw5jejZegqeyb04S0sZCOPO00p'/>
                  </div>
                  <div className="d-flex justify-content-center pt-2">
                      <Button disabled={!submitEnabled} variant="outlined" size="large" type='submit' className="secondary-button">Submit</Button>
                  </div>
              </form>
          </Modal.Body>
      </Modal>
      <Snackbar open={success.status} autoHideDuration={8000} onClose={handleStatusClose}>
        <Alert severity="success" sx={{ width: '100%' }}>
          {success.message}
        </Alert>
      </Snackbar>
      <Snackbar open={failed.status} autoHideDuration={8000} onClose={handleStatusClose}>
        <Alert severity="error" sx={{ width: '100%' }}>
        {failed.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Home;
