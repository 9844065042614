import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useParams } from "react-router-dom";

import { Container, Card, Button } from "react-bootstrap";
import { Divider } from '@mui/material';

import AdminNavbar from './AdminNavbar';

const Contact = ({ user }) => {
  const [post, setPost] = useState({});
  const { id } = useParams();
  let navigate = useNavigate();

  function handleDelete() {
    axios.delete(`https://www.coreydevelops.com/contact/${id}`, { withCredentials: true }).then(res => {
        navigate('/admin');
    }).catch(err => {
        if (err.response.status === 401) navigate('/');
    });
  };

  useEffect(() => {
    axios.get(`https://www.coreydevelops.com/contact/${id}`, { withCredentials: true }).then(res => {
        setPost(res.data.posts);
    }).catch(err => {
      navigate('/admin');
    });
  }, [])

  return (
    <>
      <AdminNavbar></AdminNavbar>
      <section id="contact-post">
        <Container className="py-5">
          <Card>
            <div className="d-flex flex-column align-items-center justify-content-center px-5 py-5">
              <h2>Name</h2>
              <Divider className="w-100 my-3"></Divider>
              <h1 className="fw-bold mb-3">{post.firstName} {post.lastName}</h1>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center px-5 py-5">
              <h2>Email</h2>
              <Divider className="w-100 my-3"></Divider>
              <h1 className="fw-bold mb-3">{post.email}</h1>
            </div>
            <div className="d-flex flex-column align-items-center justify-content-center px-5 py-5">
              <h2>Question</h2>
              <Divider className="w-100 my-3"></Divider>
              <h1 className="fw-bold mb-3">{post.question}</h1>
            </div>
            <div className="d-flex align-items-center justify-content-center px-5 py-5">
              <Button onClick={handleDelete} variant="danger" size="lg">Delete</Button>
            </div>
          </Card>
          
          
        </Container>
      </section>
    </>
  )
}
export default Contact