import { useEffect, useState } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { Instagram, FacebookOutlined, Twitter } from '@mui/icons-material';
import { LinkContainer } from 'react-router-bootstrap';
import cn from 'classnames';
import "./Navbar.css"

function Navigation() {
  const [expand, setExpand] = useState(false);

  function handleExpand() {
    setExpand((expand) => !expand);
  };

  function handleLogout() {
    window.open('https://www.coreydevelops.com/auth/logout', '_self');
  };

  return (
    <>
      <Navbar expanded={expand} onToggle={handleExpand} fixed="top" expand="lg">
          <Container>
          <LinkContainer to="/">
            <Navbar.Brand style={{ cursor: 'pointer' }} href=""><img src="/images/logo.png" alt="Corey Develops Logo" /></Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle className={cn('hamburger', 'hamburger--emphatic', {'is-active': expand})} aria-controls="basic-navbar-nav">
          <span className="hamburger-box">
            <span className="hamburger-inner"></span>
          </span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className='link-container me-auto'>
                <LinkContainer to="/">
                    <Nav.Link href="" >Home</Nav.Link>
                </LinkContainer>
                <LinkContainer to="/admin">
                    <Nav.Link href="" >Admin</Nav.Link>
                </LinkContainer>
                <Nav.Link onClick={handleLogout} href="">Logout</Nav.Link>
            </Nav>
            <div className="d-flex ms-auto">
              <Nav.Link href="https://www.instagram.com/coreydevelops/"><Instagram fontSize='large'></Instagram></Nav.Link>
              <Nav.Link href="https://www.facebook.com/coreydevelops/"><FacebookOutlined fontSize='large'></FacebookOutlined></Nav.Link>
              <Nav.Link href="https://twitter.com/coreydevelops/"><Twitter fontSize='large'></Twitter></Nav.Link>
            </div>
          </Navbar.Collapse>
          </Container>
      </Navbar>
      <div className="nav-spacer"></div>
    </>
  )
}
export default Navigation