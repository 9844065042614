import { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { Divider, Paper, Collapse, Button, styled, IconButton } from "@mui/material";
import { ExpandMoreRounded } from '@mui/icons-material';
import Aos from 'aos';
import cn from 'classnames';
import './Skills.css';



function showSkills() {
    return (
        <>
            <div className="skill-wrapper">
                <div className="title text-center px-5">
                    <h3>Principal Stack</h3>
                    <Divider></Divider>
                </div>
                <div className="body
                py-4 d-flex flex-column align-items-center">
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>MongoDB</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Express</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>React</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>NodeJS</p>
                        </Paper>
                    </div>
                </div>
            </div>
        
            <div className="skill-wrapper">
                <div className="title text-center px-5">
                    <h3>Frontend Technologies</h3>
                    <Divider></Divider>
                </div>
                <div className="body
                py-4 d-flex flex-column align-items-center">
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>ReactJS</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>HandlebarsJS</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>JavaScript</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>jQuery</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>HTML</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>CSS</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>SASS</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Bootstrap</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Material UI</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Responsive Design</p>
                        </Paper>
                    </div>
                </div>
            </div>
            <div className="skill-wrapper">
                <div className="title text-center px-5">
                    <h3>Backend Technologies</h3>
                    <Divider></Divider>
                </div>
                <div className="body
                py-4 d-flex flex-column align-items-center">
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>MongoDB</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>HandlebarsJS</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>NGINX</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>HelmetJS</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Express</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Mongoose</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Rest API</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>NodeJS</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Secure Applications</p>
                        </Paper>
                    </div>
                </div>
            </div>
            <div className="skill-wrapper">
            <div className="title text-center px-5">
                    <h3>Software Skills</h3>
                    <Divider></Divider>
                </div>
                <div className="body
                py-4 d-flex flex-column align-items-center">
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>GitHub</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Figma</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Postman</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Adobe XD</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>PhotoShop</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>NodeJS</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>NPM</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Terminal</p>
                        </Paper>
                    </div>
                    <div className="skill-container d-flex pb-3">
                        <Paper className="skill d-flex px-3 py-2 fw-bold" elevation={3}>
                            <p>Linux</p>
                        </Paper>
                    </div>
                </div>
            </div>
        </>
    );
}

const ExpandMore = styled((props) => {
    const {expand, ...others} = props;
    return <IconButton {...others}/>
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
    }),
}));

function Skills() {
    const [expanded, setExpanded] = useState(false);
    const [breakpoint, setBreakpoint] = useState(window.innerWidth < 992);

    function handleResize() {
        setBreakpoint(window.innerWidth < 992);
    };

    function handleExpand() {
        setExpanded((expanded) => !expanded);
    }    

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [])

useEffect(() => {
    Aos.init({ duration: 1000 });
    }, []);

  return (
    <section id="skills">
        <Container>
            <h1 className={cn("fw-bold text-center", {'pb-5': !breakpoint})}>My Skills</h1>
            <div data-aos="fade-up" className="d-flex justify-content-center">
                {breakpoint ? (<div className="collapse-wrapper d-flex flex-column"><Collapse in={expanded} collapsedSize={350}>{showSkills()}</Collapse><div onClick={handleExpand} className="d-flex flex-column justify-content-center align-items-center"><h5 className="view-more fw-bold">View {expanded ? 'Less' : "More"}</h5><div className="px-1"></div><ExpandMore expand={expanded} aria-expanded={expanded}
          aria-label="show more"><ExpandMoreRounded/></ExpandMore></div></div>) : showSkills()}
            </div>
            
        </Container>
    </section>
  )
}
export default Skills