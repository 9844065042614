import React, { useEffect, useState } from "react"
import { Container, Accordion, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import AdminNavbar from './AdminNavbar';

function Admin({ user }) {
    const [posts, setPosts] = useState([]);
    let navigate = useNavigate();

    function getContacts() {
        axios.get("https://www.coreydevelops.com/contact", { withCredentials: true }).then(res => {
            setPosts(res.data.posts);
        }).catch(err => {
            if (err.response.status === 401) navigate('/');
        });;
    }

    function handleDelete(id) {
        axios.delete(`https://www.coreydevelops.com/contact/${id}`, { withCredentials: true }).then(res => {
            getContacts();
        }).catch(err => {
            if (err.response.status === 401) navigate('/');
        });
      }

    useEffect(() => {
        getContacts();
    }, []);

  return (
    <>
        <AdminNavbar></AdminNavbar>
        <section id="contact">
            <Container className="py-5">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            Contacts
                        </Accordion.Header>
                        <Accordion.Body>
                            {posts.map(item => (
                                <div className="d-flex border-bottom align-items-center py-2 px-4">
                                    <h4>{item.firstName}</h4>
                                    <div className="d-flex mx-auto">
                                        <p className="h5 fw-bold">{item.email}</p>
                                    </div>
                                    
                                    <LinkContainer to={`/admin/${item._id}`}>
                                        <Button>View</Button>
                                    </LinkContainer>
                                    <div className="px-2"></div>
                                    <Button onClick={() => handleDelete(item._id)} variant="danger">Delete</Button>
                                </div>
                            ))}
                        </Accordion.Body>
                    </Accordion.Item>
                    
                </Accordion>
            </Container>
            
        </section>
    </>
  )
}
export default Admin