import { useEffect, useState } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Instagram, FacebookOutlined, Twitter } from '@mui/icons-material';
import { LinkContainer } from 'react-router-bootstrap';
import cn from 'classnames';
import './Footer.css';

function Footer(props) { 
  const [breakpoint, setbreakpoint] = useState(window.innerWidth < 576);

  function handleResize() {
    setbreakpoint(window.innerWidth < 576);
  };

  function handleLogin() {
    window.open('https://www.coreydevelops.com/auth/google', '_self');
  };

  function handleLogout() {
    window.open('https://www.coreydevelops.com/auth/logout', '_self');
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
        window.removeEventListener('resize', handleResize);
    }
  }, [])

  return (
    <footer className="mt-5">
        <Container className="py-5">
            <Row>
                <Col className={cn("logo-container", {"d-flex justify-content-center align-items-center" : breakpoint})}>
                    <img onClick={props.handleHomeClick} src="/images/logo.png" alt="Corey Develops Logo" />
                </Col>
                {breakpoint ? (<div className="w-100 py-3"></div>) : ''}
                <Col className={cn("link-container", {'d-flex align-items-center': breakpoint})}>
                    <div className={cn("d-flex flex-column", {'mx-auto' : !breakpoint})}>
                        <Nav.Link onClick={props.handleHomeClick} href="">Home</Nav.Link>
                        <Nav.Link href="#jobs">Jobs</Nav.Link>
                        <Nav.Link href="#skills">Skills</Nav.Link>
                        <Nav.Link href="#about">About</Nav.Link>
                        <Nav.Link onClick={props.handleContact} href="">Contact</Nav.Link>
                        {props.user.user ? (<Nav.Link onClick={handleLogout} href="">Logout</Nav.Link>) : (<Nav.Link onClick={handleLogin} href="">Login</Nav.Link>)}
                        {props.user.admin && (
                          <LinkContainer to="/admin">
                             <Nav.Link>Admin</Nav.Link>
                          </LinkContainer>
                        )}
                        
                    </div>
                </Col>
                {breakpoint ? (<div className="w-100 py-3"></div>) : ''}
                <Col className="social-container">
                    <div className={cn("d-flex", {'ms-auto': !breakpoint, 'mx-auto': breakpoint})}>
                        <Nav.Link href="https://www.instagram.com/coreydevelops/"><Instagram sx={{ fontSize: 50 }}></Instagram></Nav.Link>
                        <Nav.Link href="https://www.facebook.com/coreydevelops/"><FacebookOutlined sx={{ fontSize: 50 }}></FacebookOutlined></Nav.Link>
                        <Nav.Link href="https://twitter.com/coreydevelops/"><Twitter sx={{ fontSize: 50 }}></Twitter></Nav.Link>
                    </div>
                </Col>
            </Row>
        </Container>
    </footer>
  )
}
export default Footer